.weatherCard{
    text-align: center;
    margin-top: 3%;
    width: 19rem;
    height: 37.2rem;
    box-shadow: 1px 2px silver;
    font-size: 1.2rem;
} 

.boldText{
    font-weight: bold;
}

.iconStyle{
    color: gray;
    margin-top:10%;
    margin-bottom: 20%;
}

/* @media (max-width: 992px) {
    .weatherCard{
        width: 100%;
        font-size: 1.3rem;
    }
} */

@media (min-width: 1440px) {
    .weatherCard{
        margin-top: 3%;
        /* width: 100%; */
        /* height: 40.5rem; */
        font-size: 1.3rem;
    }
}

@media (max-width: 1199px) {
    .weatherCard{
        width: 100%;
        margin-top: 3%;
        font-size: 1.3rem;
    }

    .weatherCard>p{
        font-size: .8px;
    }
}