.buttonStyle {
    display: block;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0.6rem 1.25rem;
    font-size: 1rem;
    border-radius: 15px;
    background: #006241;
    border: 2px solid #edf2f7;
    color: white;
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
}

.buttonStyle:hover {
    background-color:#014421 ;
}

.buttonStyle:focus {
    background-color:#014421;
    outline: none;
}

.formContainer {
    align-self: center;
    padding: 2%;
}

.formStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.formContainer>p {
    font-size: 1rem;
    color: #000;
    font-style: normal;
    font-weight: 500;
    text-shadow: 0.5px 0.5px 0.5px rgba(26, 1, 1, 0.568);
    text-align: center;
}

.formContainer>hr {
    background-color: #eee;
    width: 8cm;
}

@media (max-width: 992px) {
    .formContainer{
        margin-top: 7rem;
    }
}