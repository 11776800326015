.error404Container{
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
}
  
.error404{
    margin: 0 auto; 
    padding: 0 auto;
    margin-top: 2%;
}
  
.error404img{
    width: 40vw;
    height: 50vh;    
    border: solid 1px steelblue;
    margin-top: 5%;
    margin-bottom: 10%;
}

@media (max-width: 992px) {
    .error404img{
        width: 100%;
        height: auto;
    }
    .error404>h5{
        font-size: large;
        word-spacing: 2px;
        line-height: 3ch;
    }
}